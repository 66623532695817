import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ethers,
  utils,
  ContractReceipt,
  ContractTransaction,
  BigNumber,
} from 'ethers';
import { truncate } from 'truncate-ethereum-address';
import { Form } from '@unform/web';
import { useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { HiOutlineInformationCircle, HiArrowNarrowRight } from 'react-icons/hi';

import Swal from 'sweetalert2';
import getValidationErros from '~/utils/getValidationsErrors';

import api from '~/services/api';
import swalError from '~/utils/swalError';
import WalletAvatarPageswap from '~/components/WalletAvatarPageswap';
import { web3store } from '~/store';
import {
  formatPrice,
  formatUSDValueFromWei,
  formatEthValue,
} from '~/utils/format';

import { Container, Card, SelectCoin, ConnectBtn, RefreshBtn } from './styles';
import InputMask from '~/components/InputMask';
// import ModalConfirmation from '~/pages/Sales/ModalConfirmation';
import Loading from '~/components/Loading';
import ModalExchangeSuccessful from '~/components/ModalExchangeSuccessful';
import ModalClearApproval from '~/components/ModalClearApproval';

import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';
import { abi as EthUsdtSwapV3Abi } from '~/abi/EthUsdtSwapV3.json';

import arrows from '~/assets/icons/arrows-swap.svg';
import logoSwap from '~/assets/logo/logo-swap.png';
import logoBusd from '~/assets/logo/logo-busd.svg';
import logoUsdpi from '~/assets/logo/logo-usdpi.svg';
import logoEth from '~/assets/logo/logo-eth.svg';
import logoBnb from '~/assets/logo/logo-bnb.svg';
import logoUsdt from '~/assets/logo/logo-usdt.svg';
import logoUsdc from '~/assets/logo/logo-usdc.svg';
import logoGusd from '~/assets/logo/logo-gusd.svg';
import arrow from '~/assets/icons/arrow.svg';
import swapRight from '~/assets/logo/swap-right.svg';
import refreshBalances from '~/assets/icons/refresh-balances.svg';
import logoWhite from '~/assets/logo/logo-p-white.svg';
import WalletLink from '~/components/WalletLink';
import { abi as erc20Abi } from '~/abi/erc20.json';
import { abi as usdtBank } from '~/abi/EthUsdtSwapV3.json';

interface IToken {
  logo: string;
  name: string;
  descriptionBnb: string;
  descriptionEth: string;
  priceBnb: string;
  priceEth: string;
  cardStyle: string;
}

interface INetwork {
  logo: string;
  name: string;
}

interface HashData {
  amt2authorize: string;
  chain: number;
  deadline: number;
  name: string;
  nonce: number;
}

/* eslint no-underscore-dangle: 0 */
const PageSwap: React.FC = () => {
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [invertCard, setInvertCard] = useState(false);
  const [exchangeValue, setExchangeValue] = useState('0,00');
  const [exchangeValueEth, setExchangeValueEth] = useState('0,00');
  const [feeValue, setFeeValue] = useState('0,00');
  const [receiveValue, setReceiveValue] = useState('0,00');
  const [error, setError] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  const [debouncedValue, setDebouncedValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorExchange, setErrorExchange] = useState('');
  const [type, setType] = useState('');
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  const [pollingEnabled, setPollingEnabled] = useState(false);
  const [selectedMode, setSelectedMode] = useState('BUY');
  const [account, setAccount] = useState<string | null>(null);
  const [ethBalance, setEthBalance] = useState<string | null>(null);
  const [ethValue, setEthValue] = useState('0');
  const [usdpiBalance, setUsdpiBalance] = useState('0');
  const [lastUsdpiBalance, setLastUsdpiBalance] = useState('0');
  const [balbusd, setBalbusd] = useState<string | null>(null);
  const [balusdpi, setBalusdpi] = useState<string | null>(null);
  const [balusdt, setBalusdt] = useState<string | null>(null);
  const [liqusdt, setLiqusdt] = useState<string | null>(null);
  const [liqbusd, setLiqbusd] = useState<string | null>(null);
  const [isValidSwap, setIsValidSwap] = useState(false);
  const [placeHolder, setPlaceHolder] = useState('PLEASE CONNECT WALLET');
  // const [placeHolder, setPlaceHolder] = useState('Enter amount here');
  const [actionBtnText, setActionBtnText] = useState('Exchange');
  const inputMaskRef = useRef(null);
  const [processText, setProcessText] = web3store.useState('processText');
  const [showCongrat, setShowCongrat] = useState(false);
  // const [congratHash, setCongratHash] = useState(null);
  const [congratHash, setCongratHash] = useState<string | null>(null);

  const [showUpdate, setShowUpdate] = useState(false);
  const [showUpdateComplete, setShowUpdateComplete] = useState(false);
  const [usdtAllowance, setUsdtAllowance] = useState<number | null>(null);
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  const tokenETH: IToken = {
    logo: logoEth,
    name: 'ETH',
    descriptionBnb: '',
    descriptionEth: '',
    priceEth: '-',
    priceBnb: '$0.00',
    cardStyle: 'linear-gradient(85deg, #0554BB 10.36%, #0B65DB 97.55%);',
  };
  const tokenUSDT: IToken = {
    logo: logoUsdt,
    name: 'USDT',
    descriptionBnb:
      'USDT In the Binance Smart Chain (BEP20) network liquidity in reserves:',
    descriptionEth:
      'USDT In the Ethereum (ERC20) network liquidity in reserves:',
    priceEth: '-',
    priceBnb: '$0.00',
    cardStyle: 'linear-gradient(84.9deg, #26A183 10.36%, #25A07A 97.55%)',
  };
  const [tokens, setTokens] = useState<IToken[]>([tokenUSDT, tokenETH]);
  const [tokenSelected, setTokenSelected] = useState<IToken>(tokenUSDT);
  const [networks, setNetworks] = useState<INetwork[]>([
    {
      logo: logoEth,
      name: 'ETH',
    },
    // {
    //   logo: logoBnb,
    //   name: 'BNB',
    // },
  ]);
  const [networkSelected, setNetworkSelected] = useState<INetwork>({
    logo: logoEth,
    name: 'ETH',
  });

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // FORMATTED VALUES
  const busd = useMemo(() => {
    if (balbusd === null) {
      ('-');
    } else {
      formatPrice(parseFloat((parseInt(balbusd, 10) / 10 ** 18).toFixed(2)));
    }
  }, [balbusd]);

  const usdt = useMemo(() => {
    // console.log('balusdt: %s', balusdt);
    if (balusdt === null) {
      return '-';
    }
    return formatPrice(
      parseFloat((parseInt(balusdt, 10) / 10 ** 6).toFixed(2))
    );
  }, [balusdt]);

  const ethBal_f = useMemo(() => {
    if (ethBalance === null) {
      return '-';
    }
    const truncatedValue =
      Math.floor((parseInt(ethBalance, 10) / 10 ** 18) * 10000) / 10000;
    const formattedEthValue = Intl.NumberFormat('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 6,
    }).format(truncatedValue); // Format the ETH value with 18 decimal places
    return formattedEthValue;
    // return formatEthValue(truncatedValue.toString());
    // console.log('truncatedValue: %s', truncatedValue);
    // return ethBalance;
  }, [ethBalance]);

  const usdpi = useMemo(() => {
    if (balusdpi === null) {
      return '-';
    }
    const truncatedValue =
      Math.floor((parseInt(balusdpi, 10) / 10 ** 18) * 100) / 100;
    return formatPrice(truncatedValue);
  }, [balusdpi]);

  const usdt_liq_f = useMemo(() => {
    if (liqusdt === null) {
      return '-';
    }
    return formatPrice(
      parseFloat((parseInt(liqusdt, 10) / 10 ** 6).toFixed(2))
    );
  }, [liqusdt]);

  const busd_liq_f = useMemo(() => {
    if (liqbusd === null) {
      return '-';
    }
    return formatPrice(
      parseFloat((parseInt(liqbusd, 10) / 10 ** 18).toFixed(2))
    );
  }, [liqbusd]);

  const usdt_allowance_f = useMemo(() => {
    if (usdtAllowance === null) {
      return '-';
    }
    return formatUSDValueFromWei(usdtAllowance.toString());
  }, [usdtAllowance]);

  const eth_value_f = useMemo(() => {
    // console.log('ethValue: %s type %s', ethValue, typeof ethValue);
    if (ethValue === '0.00') {
      return '-';
    }
    const ethValueNumber = parseFloat(utils.formatUnits(ethValue, 6));
    const formattedValue = Intl.NumberFormat('en', {
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(ethValueNumber); // Format the ETH value with 18 decimal places
    return formattedValue;
    // return 'xxx';
  }, [ethValue]);

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  const updatePriceEthByName = (name: string, newPriceEth: string) => {
    setTokens((prevTokens) =>
      prevTokens.map((token) =>
        token.name === name ? { ...token, priceEth: newPriceEth } : token
      )
    );
  };

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    const updateTokenPrice = () => {
      // Check if liqusdt is not null and is a valid string
      if (liqusdt !== null && typeof liqusdt === 'string') {
        // Parse the wei string to a number, divide by 10^6, and format it
        const parsedLiqusdt = (parseFloat(liqusdt) / 10 ** 6).toFixed(2);
        const formattedPrice = `$${formatPrice(parseFloat(parsedLiqusdt))}`;
        updatePriceEthByName('USDT', formattedPrice); // Update token state array
      }
    };
    // Call the callback function
    updateTokenPrice();
  }, [liqusdt]);

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  const getEthPrice = (usdValueStr: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await api.get(
            `v1/exchange/quote/eth/usdpi/${usdValueStr}`
          );
          resolve(response.data.estimate.net.toString());
          // reject(0);
        } catch (ethPriceError) {
          reject(ethPriceError);
        }
      })();
    });
  };

  useEffect(() => {
    // console.log('+++++ exchangeValue changed to: %s +++++', exchangeValue);
    const handler = setTimeout(() => {
      setDebouncedValue(exchangeValue);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [exchangeValue]);

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // Update send/receive information section following
  // pause for user to stop entering data
  useEffect(() => {
    if (debouncedValue) {
      console.log(
        '+++++ debouncedValue: %s type: %s +++++',
        debouncedValue,
        typeof debouncedValue
      );
      const value = parseFloat(debouncedValue.replaceAll(',', ''));
      // console.log('+++++ value: %s +++++', value);
      if (value === 0) {
        setReceiveValue(formatPrice(value));
        setFeeValue(formatPrice(0));
        setExchangeValueEth('0');
      } else if (invertCard) {
        const amt = value * 10 ** 6;
        console.log('getting fee');
        // let transFees = 0;
        api
          .get(`v1/usdpi/fee/estimate/usdt/${amt}`)
          .then((response) => {
            const fee = response.data.estimate.fee / 10 ** 6;
            const net = response.data.estimate.net / 10 ** 6;
            setFeeValue(formatPrice(fee));
            setReceiveValue(formatPrice(net));
            if (net === 0) {
              setError(
                'The value entered does not cover the fees for this transaction'
              );
              setIsValidSwap(false);
            }
          })
          .catch((feeerror) => {
            console.error('Error fetching data:', feeerror);
          });
      } else if (tokenSelected.name === 'ETH') {
        // ETH values have to be validated here
        const valueAsETH = value / (parseFloat(ethValue) / 10 ** 6);
        // console.log('valueAsETH: %s', valueAsETH);
        const valueAsETHString = valueAsETH.toFixed(18);
        // console.log('valueAsETHString: %s', valueAsETHString);
        const weiAmountString = ethers.utils
          .parseUnits(valueAsETHString, 'ether')
          .toString();
        // console.log('weiAmount: %s', weiAmountString);
        if (value === 0) {
          setActionBtnText('Exchange');
          setIsValidSwap(false);
        } else if (ethBalance !== null) {
          const ethBalFloat = parseFloat(ethBalance) / 10 ** 18;
          // console.log('+++++ ethBalFloat: %s +++++', ethBalFloat);
          if (valueAsETH > ethBalFloat) {
            setError('The value entered exceeds your ETH balance');
            setActionBtnText('Exchange');
            setIsValidSwap(false);
          } else {
            setError('');
            setActionBtnText(`Send ${exchangeValueEth} ETH and purchase USDPI`);
            setIsValidSwap(true);
          }
          // validate
          setFeeValue(formatPrice(0));
          setReceiveValue(formatPrice(value));
          setExchangeValueEth(formatEthValue(weiAmountString));
        }
        setFeeValue(formatPrice(0));
      } else {
        setFeeValue(formatPrice(0));
        setReceiveValue(formatPrice(value));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedValue,
    invertCard,
    tokenSelected,
    ethValue,
    ethBalance,
    exchangeValueEth,
  ]);

  const handleInvertCard = useCallback(() => {
    setError('');
    setInvertCard(!invertCard);
  }, [busd, exchangeValue, invertCard, usdpi]);

  // Called by changes in inputmask
  // Will pre-validate amount against balance and
  // avail liquidity before passing up for display
  // in the send/receive section
  const handleChangePrice = useCallback(
    async (e) => {
      setError('');
      console.log('handleChangePrice');
      // console.log('+++++ account: %s +++++', account);
      const value = parseFloat(e.target.value.slice(1).replaceAll(',', ''));
      if (account === null) {
        // console.log('Not connected - clearing value');
        setExchangeValue('0.00');
        setActionBtnText('Exchange');
        setIsValidSwap(false);
        setExchangeValueEth('0');
      } else if (!invertCard && tokenSelected.name === 'ETH') {
        // ETH validation takes place in debouncedValue effect
        console.log('ETH - skipping validation');
        if (value === 0) {
          setActionBtnText('Exchange');
          setIsValidSwap(false);
          setExchangeValue('0.00');
        } else {
          setExchangeValue(e.target.value.slice(1));
        }
      } else {
        // console.log('+++++ handleChangePrice - value: %s +++++', value);
        // const coinBusd = parseFloat(busd.replaceAll(',', ''));
        const coinUsdpi = parseFloat(usdpi.replaceAll(',', ''));
        const coinUsdt = parseFloat(usdt.replaceAll(',', ''));
        const liqUsdt = parseFloat(usdt_liq_f.replaceAll(',', ''));
        // console.log('+++++ handleChangePrice - coinUsdpi: %s +++++', coinUsdpi);
        // console.log('+++++ handleChangePrice - coinUsdt: %s +++++', coinUsdt);
        // console.log('+++++ handleChangePrice - liqUsdt: %s +++++', liqUsdt);
        if (value === 0) {
          setActionBtnText('Exchange');
          setIsValidSwap(false);
          setExchangeValue('0.00');
        } else if (invertCard && value > coinUsdpi) {
          setError(
            'The value entered must be less than or equal to your amount of coins USDPI'
          );
          setExchangeValue('0.00');
          setActionBtnText('Exchange');
          setIsValidSwap(false);
        } else if (invertCard && value > liqUsdt) {
          setError(
            'The value entered must be less than or equal to avail USDPI liquidity'
          );
          setExchangeValue('0.00');
          setActionBtnText('Exchange');
          setIsValidSwap(false);
        } else if (invertCard && value < 2) {
          setError('Minimum value to exchange is $2.00');
          setExchangeValue('0.00');
          setActionBtnText('Exchange');
          setIsValidSwap(false);
        } else if (!invertCard && value > coinUsdt) {
          setError(
            'The value entered must be less than or equal to your amount of coins USDT'
          );
          setExchangeValue('0.00');
          setActionBtnText('Exchange');
          setIsValidSwap(false);
        } else if (!invertCard) {
          setActionBtnText(`Authorize $${value.toFixed(2)} and Buy USDPI`);
          setExchangeValue(e.target.value.slice(1));
          setIsValidSwap(true);
        } else if (invertCard) {
          setActionBtnText(`Sell $${value.toFixed(2)} USDPI`);
          setExchangeValue(e.target.value.slice(1));
          setIsValidSwap(true);
          // } else {
          //   setActionBtnText('Exchange');
          //   setExchangeValue(e.target.value.slice(1));
        }
      }
    },
    [
      invertCard,
      usdpi,
      usdt,
      usdt_liq_f,
      account,
      exchangeValueEth,
      tokenSelected,
    ]
  );

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  const showMaxBtn = useCallback((): boolean => {
    // return account !== null && tokenSelected.name !== 'ETH';
    return false;
  }, [account, tokenSelected]);

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  const handleMax = useCallback(() => {
    setError('');
    // Get float versions of balances & liquidity
    const coinUsdpi = parseFloat(usdpi.replaceAll(',', ''));
    // console.log('+++++ coinUsdpi: %s +++++', coinUsdpi);
    const liqUsdt = parseFloat(usdt_liq_f.replaceAll(',', ''));
    const coinUsdt = parseFloat(usdt.replaceAll(',', ''));
    const allowanceUsdt = parseFloat(usdt_allowance_f.replaceAll(',', ''));
    if (invertCard) {
      console.log('+++++ coinUsdpi: %s +++++', coinUsdpi);
      const amt = coinUsdpi * 1000000;
      console.log('+++++ amt: %s +++++', amt);
      api
        .get(`v1/usdpi/fee/estimate/usdt/${amt}`)
        .then((response) => {
          const fee = response.data.estimate.fee / 10 ** 6;
          const net = response.data.estimate.net / 10 ** 6;
          setFeeValue(formatPrice(fee));
          setReceiveValue(formatPrice(net));
          if (net === 0) {
            setError(
              'The value entered does not cover the fees for this transaction'
            );
            setIsValidSwap(false);
          }
        })
        .catch((feeerror) => {
          console.error('Error fetching data:', feeerror);
        });
      if (coinUsdpi === 0) {
        setError('Zero balance available available.');
        setActionBtnText(`Exchange`);
        setIsValidSwap(false);
        setExchangeValue('0.00');
      } else if (liqUsdt === 0) {
        setError('There is currently no USDT liquidity available.');
        setExchangeValue(usdpi);
        setActionBtnText('Exchange');
        setIsValidSwap(false);
      } else if (coinUsdpi > liqUsdt) {
        setError(
          'The value entered must be less than or equal to available USDT liquidity'
        );
        setExchangeValue(usdpi);
        setActionBtnText('Exchange');
        setIsValidSwap(false);
      } else if (coinUsdpi < 2) {
        setError('Minimum value to exchange is $2.00');
        setExchangeValue('0.00');
        setActionBtnText('Exchange');
        setIsValidSwap(false);
      } else {
        setActionBtnText(`Sell $${usdpi} USDPI`);
        setIsValidSwap(true);
        setExchangeValue(usdpi);
      }
    } else if (tokenSelected.name === 'ETH') {
      // do nothing
    } else if (!invertCard && coinUsdt === 0) {
      // console.log('+++++ HIT +++++');
      setExchangeValue('0.00');
      setActionBtnText(`Exchange`);
      setIsValidSwap(false);
    } else if (!invertCard) {
      setExchangeValue(usdt);
      setActionBtnText(`Authorize $${usdt} & Buy USDPI`);
      setIsValidSwap(true);
    }
  }, [invertCard, usdpi, usdt, usdt_liq_f, exchangeValue, tokenSelected]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const fetchBalances = async () => {
    // console.log('+++++ FETCH BALANCES +++++');
    try {
      if (account) {
        const response = await api.get(`v2/member/tokenBalances/${account}`);
        // console.log(response);
        if (response.data.balances.wallet_eth !== ethBalance) {
          setEthBalance(response.data.balances.wallet_eth);
        }
        if (response.data.balances.wallet_usdpi !== balusdpi) {
          // console.log(
          //   'response.data.balances.wallet_usdpi: %s',
          //   response.data.balances.wallet_usdpi
          // );
          setBalusdpi(response.data.balances.wallet_usdpi);
        }
        if (response.data.balances.wallet_usdt !== balusdt) {
          setBalusdt(response.data.balances.wallet_usdt);
        }
        return { balances: response.data.balances };
      }
    } catch (balanceError) {
      console.log(balanceError);
      setEthBalance('0');
      setUsdpiBalance('0');
      setLoading(false);
      throw balanceError;
    }
    return { balances: {} };
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const fetchLiquidity = async () => {
    // console.log('+++++ FETCH LIQUIDITY +++++');
    try {
      if (account) {
        const response = await api.get(`v1/exchange/liquidity`);
        // console.log(response);
        if (response.data.liquidity.usdt !== liqusdt) {
          setLiqusdt(response.data.liquidity.usdt);
          // setLiqusdt('125000000'); // +++++ TEST ONLY
        }
        if (response.data.liquidity.busd !== liqbusd) {
          setLiqbusd(response.data.liquidity.busd);
        }
        return { liquidity: response.data.liquidity };
      }
    } catch (balanceError) {
      console.log(balanceError);
      setEthBalance('0');
      setUsdpiBalance('0');
      setLoading(false);
      throw balanceError;
    }
    return { balances: {} };
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const fetchAllBalances = async () => {
    fetchBalances();
    fetchLiquidity();
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const getSignature = (hashData: HashData) => {
    return new Promise((resolve, reject) => {
      (async () => {
        setProcessText('Waiting for signature in wallet');
        // console.log(hashData);
        // console.log(process.env.REACT_APP_USDPI_BURNER);
        const { deadline, amt2authorize, nonce, name, chain } = hashData;
        const version = '1';
        const domainData = {
          name,
          version,
          chainId: chain,
          verifyingContract: process.env.REACT_APP_BSC_USDPI,
        };
        const types = {
          Permit: [
            {
              name: 'owner',
              type: 'address',
            },
            {
              name: 'spender',
              type: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
            },
            {
              name: 'nonce',
              type: 'uint256',
            },
            {
              name: 'deadline',
              type: 'uint256',
            },
          ],
        };
        const val = {
          owner: account,
          spender: process.env.REACT_APP_USDPI_BURNER,
          value: amt2authorize,
          nonce,
          deadline,
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        let sig;
        try {
          sig = await tsigner._signTypedData(domainData, types, val);
          resolve(sig);
        } catch (sigerror) {
          // code to handle errors during signing of typed data
          console.error(sigerror); // log the error message to the console
          reject(sigerror);
        }
      })();
    });
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const executeSwapUsdpi = (hashData: HashData, sig: string) => {
    return new Promise((resolve, reject) => {
      (async () => {
        const { deadline, amt2authorize, nonce, name, chain } = hashData;
        api
          .post('/v1/exchange/sell4usdt', {
            owner: account,
            amount: amt2authorize,
            deadline,
            signature: sig,
          })
          .then(function (sallresponse) {
            // console.log(sallresponse);
            resolve('OK');
          })
          .catch(function (sellerror) {
            console.log('================= ERROR ===================');
            console.error(sellerror);
            reject(sellerror);
          });
      })();
    });
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Get current approved USDT amount
  // ++++++++++++++++++++++++++++++++++++++
  const getUsdtAllowance = useCallback(async () => {
    // console.log('+++++ getUsdtAllowance +++++');
    // console.log(process.env.REACT_APP_ETH_SWAP);
    if (account !== null) {
      const response = await api.get(`v1/exchange/allowance/usdt/${account}`);
      // console.log(response.data.allowance);
      setUsdtAllowance(parseInt(response.data.allowance, 10));
    }
  }, [account]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Get current ETH price
  // ++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    getUsdtAllowance();
    getEthPrice(1000000000000000000)
      .then((priceResult) => {
        console.log('+++++ ETH PRICE: %s +++++', priceResult);
        setEthValue(priceResult);
      })
      .catch((priceError) => {
        setEthValue('0.00');
      });
    // setEthValue
  }, [account]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleApproveUsdt = (usdtWeiString: string) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          // console.log(usdtWeiString);
          const tprovider = new ethers.providers.Web3Provider(window.ethereum);
          const tsigner = tprovider.getSigner();
          const contractusdt = new ethers.Contract(
            process.env.REACT_APP_BSC_USDT as string,
            erc20Abi,
            tprovider
          );
          const overrides = { gasLimit: 50000 };

          const allowance = await contractusdt.allowance(
            await tsigner.getAddress(),
            process.env.REACT_APP_ETH_SWAP
          );

          if (BigNumber.from(allowance).gte(BigNumber.from(usdtWeiString))) {
            // If the allowance is greater than or equal to the desired amount, no need to approve again
            console.log('Approval already exists');
            resolve('EXISTS'); // Resolve the promise without initiating approval
          } else if (BigNumber.from(allowance).gt(BigNumber.from('0'))) {
            // User has existing allowance > 0...reset to 0
            console.log('Must reset approval');
            resolve('RESET');
          } else {
            setProcessText('Requesting approval, please confirm transaction.');
            // If the allowance is less than the desired amount, proceed with approval
            const trxapprove = await contractusdt
              .connect(tsigner)
              .approve(process.env.REACT_APP_ETH_SWAP, usdtWeiString, {
                from: await tsigner.getAddress(),
                ...overrides,
              });

            // console.log(trxapprove);
            setProcessText('Waiting for approval transaction to complete');

            const receipt = await trxapprove.wait(3);

            // console.log('***********************');
            // console.log(receipt);
            // console.log('***********************');
            resolve(receipt); // Resolve the promise when the approval is successful
          }
        } catch (approveerror) {
          console.error(approveerror);
          // setUsdpiPurchaseAmt('');
          // setUsdpiPurchaseTX('ERROR: Approval error');
          reject(approveerror); // Reject the promise when there is an error
        }
      })();
    });
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const executeSwapUsdt = (usdtWeiString: string) => {
    return new Promise((resolve, reject) => {
      try {
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);

        // Check if the user is connected and has provided access
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((accounts: string[]) => {
            if (!accounts || !accounts[0]) {
              reject(new Error('User denied access or not connected'));
              return;
            }

            const owner = accounts[0];
            const tsigner = tprovider.getSigner(owner);

            const contract = new ethers.Contract(
              process.env.REACT_APP_ETH_SWAP as string,
              usdtBank, // Replace with your contract ABI
              tprovider
            );

            const overrides = { gasLimit: 100000 };
            setProcessText(
              'Ready to swap.  Please confirm the transaction in your wallet'
            );

            contract
              .connect(tsigner)
              .depositUsdt(owner, usdtWeiString, overrides)
              .then((trx: any) => {
                // console.log(trx);
                setProcessText('Waiting for transaction on blockchain');

                trx
                  .wait(3)
                  .then((receipt: any) => {
                    // console.log('***********************');
                    // console.log(receipt);
                    // console.log('***********************');
                    const txHash = receipt.transactionHash.toString();
                    api
                      .post('/v1/usdpi/deposit/usdt/tx', {
                        nft_id: null,
                        tx: txHash,
                      })
                      .then(function (acctresponse) {
                        resolve(txHash); // Resolve the promise when the swap is successful
                      })
                      .catch(function (logerror) {
                        console.error(logerror);
                        reject(logerror); // Reject the promise when there is an error
                      });
                  })
                  .catch((waiterror: Error) => {
                    console.error(waiterror);
                    reject(waiterror); // Reject the promise when there is an error
                  });
              })
              .catch((depositerror: Error) => {
                console.error(depositerror);
                reject(depositerror); // Reject the promise when there is an error
              });
          })
          .catch((accounterror: Error) => {
            console.error(accounterror);
            reject(accounterror); // Reject the promise when there is an error
          });
      } catch (promiserror) {
        console.error(promiserror);
        reject(promiserror); // Reject the promise when there is an error
      }
    });
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const logExchange = useCallback((hash: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      console.log('+++++ logExchange +++++');
      api
        .post('/v1/usdpi/deposit/usdt/tx', {
          nft_id: 0,
          tx: hash,
        })
        .then(function (acctresponse) {
          setProcessText(
            'Exchange accepted. Waiting for bridged USDPI to arrive.'
          );
          resolve('Complete');
        })
        .catch(function (logError) {
          console.log('== ERROR ==');
          console.error(logError);
          reject(logError);
        });
    });
  }, []);

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  const handleSubmitExchange = useCallback(async () => {
    setShowUpdateComplete(false);
    if (invertCard) {
      // Selling USDPI for USDT
      setProcessText('Preparing Swap');
      setLoading(true);
      console.log('Selling usdpi');
      console.log('debouncedValue: ', debouncedValue);
      const usdpiWeiString = Math.floor(
        parseFloat(debouncedValue) * 10 ** 18
      ).toString();
      console.log('usdtWeiString: ', usdpiWeiString);

      networkSwitch('NETWORK')
        .then(async (response) => {
          // console.log('+++++ network switched +++++');
          api
            .get('/v1/usdpi/sell/', {
              params: {
                wallet_address: account,
                amt2authorize: usdpiWeiString,
              },
            })
            .then(function (acctresponse) {
              const { data } = acctresponse;
              // console.log(data);
              getSignature(data)
                .then((signature) => {
                  // console.log('+++++ signature: %s +++++', signature);
                  setProcessText('Sending signed transaction');
                  executeSwapUsdpi(data, signature as string)
                    .then((sellResult) => {
                      // congratHash
                      setExchangeValue('0,00');
                      setActionBtnText('Exchange');
                      setIsValidSwap(false);
                      setLoading(false);
                      setCongratHash(null);
                      setShowCongrat(true);
                      fetchAllBalances();
                    })
                    .catch((sellError) => {
                      setLoading(false);
                      swalError({
                        message: 'Swap transaction failed.',
                        textButton: 'Try Again',
                      });
                    });
                })
                .catch((sigerror) => {
                  console.log('+++++ sigerror: %s +++++', sigerror);
                  setLoading(false);
                  swalError({
                    message: 'There was a problem getting your signature.',
                    textButton: 'Try Again',
                  });
                });
              // setLoading(false);
            })
            .catch(function (hasheserror) {
              console.log('================= ERROR ===================');
              console.log(hasheserror);
              setLoading(false);
            });
        })
        .catch((switchError) => {
          console.log(switchError);
          setLoading(false);
          swalError({
            message: 'There was a problem switching networks in your wallet',
            textButton: 'Try Again',
          });
        });
    } else if (tokenSelected.name === 'ETH') {
      console.log('+++++ SWAP ETH +++++');
      setProcessText(
        'Preparing Swap.  Please confirm transaction in your wallet.'
      );
      setLoading(true);

      networkSwitch('PAY')
        .then(async (response) => {
          const tprovider = new ethers.providers.Web3Provider(window.ethereum);
          const tsigner = tprovider.getSigner();
          const swapBank = new ethers.Contract(
            process.env.REACT_APP_ETH_SWAP as string,
            EthUsdtSwapV3Abi,
            tprovider
          );
          // Execute exchange
          const ethExchangeValueFloat = parseFloat(exchangeValueEth) * 10 ** 18;
          const ethExchangeValue = ethExchangeValueFloat.toString();
          console.log(
            '+++++ ethExchangeValueFloat: %s +++++',
            ethExchangeValueFloat
          );
          console.log('+++++ ethExchangeValue: %s +++++', ethExchangeValue);
          swapBank
            .connect(tsigner)
            .swapEth4Usdt(ethExchangeValue, 0, {
              value: ethExchangeValue,
            })
            // .swapEth4Usdt('1000000000000000', 0, {
            //   value: '1000000000000000',
            // })
            .then((tx: ContractTransaction) => {
              // console.log('+++++ ContractTransaction +++++');
              // console.log(tx);
              setProcessText('Exchange - waiting for transaction to complete');
              console.log('Transaction sent successfully:', tx);

              // Start polling for the transaction receipt
              const interval = 10000; // Check every 10 seconds
              const timeout = 300000; // Stop checking after 5 minutes
              const intervalId = setInterval(async () => {
                try {
                  console.log('+++++ Getting receipt +++++');
                  const receipt = await tsigner.provider.getTransactionReceipt(
                    tx.hash
                  );
                  if (receipt && receipt.confirmations > 0) {
                    console.log(receipt);
                    clearInterval(intervalId);
                    setProcessText('Exchange - bridging funds');
                    logExchange(tx.hash)
                      .then((logResult) => {
                        setTimeout(() => {
                          setExchangeValue('0,00');
                          setActionBtnText('Exchange');
                          setIsValidSwap(false);
                          setLoading(false);
                          setCongratHash(tx.hash);
                          setShowCongrat(true);
                          fetchBalances();
                        }, 10000);
                      })
                      .catch((logError) => {
                        console.error(logError);
                        setLoading(false);
                        // reject(logError);
                      });
                  }
                } catch (seekError) {
                  setLoading(false);
                  console.error(
                    'Error while checking transaction status:',
                    seekError
                  );
                }
              }, interval);

              // Set a timeout to stop checking
              setTimeout(() => {
                clearInterval(intervalId);
                // Handle the case where the transaction is not mined within the timeout
                console.error(
                  'Transaction was not mined within the specified timeout.'
                );
                logExchange(tx.hash)
                  .then((logResult) => {
                    // resolve('Complete');
                    setLoading(false);
                  })
                  .catch((logError) => {
                    // reject(logError);
                    setLoading(false);
                  });
              }, timeout);
            })
            .catch((ethSwapError: Error) => {
              console.error('Error during swap:', ethSwapError);
              setLoading(false);
              // reject(error);
            });
        })
        .catch((switchError) => {
          console.log(switchError);
          setLoading(false);
          swalError({
            message: 'There was a problem switching networks in your wallet',
            textButton: 'Try Again',
          });
        });
    } else {
      // Buying USDPI with USDT
      setProcessText('Preparing Swap');
      setLoading(true);
      // console.log('Buying usdpi');
      // console.log('debouncedValue: ', debouncedValue);
      const usdtWeiString = Math.floor(
        // parseFloat(debouncedValue) * 10 ** 6
        parseFloat(debouncedValue.replace(/[^0-9.]/g, '')) * 10 ** 6
      ).toString();
      // console.log('usdtWeiString: ', usdtWeiString);

      networkSwitch('PAY')
        .then(async (response) => {
          // console.log('+++++ network switched +++++');
          handleApproveUsdt(usdtWeiString)
            .then((approvalResponse) => {
              // console.log('Approval response: %s', approvalResponse);
              if (approvalResponse === 'RESET') {
                setLoading(false);
                setShowUpdate(true);
              } else {
                executeSwapUsdt(usdtWeiString)
                  .then((swapTX) => {
                    const swapTXString = swapTX as string;
                    // console.log('Swap successful');
                    // console.log(swapTX);
                    setExchangeValue('0,00');
                    setActionBtnText('Exchange');
                    setIsValidSwap(false);
                    setLoading(false);
                    // setCongratHash(swapTXString);
                    setCongratHash(swapTXString);
                    setShowCongrat(true);
                    fetchBalances();
                  })
                  .catch((swapError) => {
                    console.log('Swap failed');
                    setLoading(false);
                    swalError({
                      message: 'Swap transaction failed.',
                      textButton: 'Try Again',
                    });
                  });
              }
            })
            .catch((approvalError) => {
              console.log('Approval failed');
              setLoading(false);
              swalError({
                message: 'Approval failed.',
                textButton: 'Try Again',
              });
            });
        })
        .catch((switchError) => {
          console.log(switchError);
          setLoading(false);
          swalError({
            message: 'There was a problem switching networks in your wallet',
            textButton: 'Try Again',
          });
        });
    }
  }, [invertCard, debouncedValue, tokenSelected, exchangeValueEth]);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClick = useCallback((typeData) => {
    setType((state) => (state === typeData ? '' : typeData));
  }, []);

  const handleSelectToken = useCallback(
    (token) => {
      console.log('+++++ handleSelectToken: %s +++++', token.name);
      console.log(actionBtnText);
      setType('');
      setExchangeValue('0.00');
      setActionBtnText('Exchange');
      setIsValidSwap(false);
      setExchangeValueEth('0');
      setTokenSelected(token);
    },
    [actionBtnText]
  );

  const handleSelectNetwork = useCallback((network) => {
    setNetworkSelected(network);
    setType('');
  }, []);

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setError('');
    setTokenSelected(tokenUSDT);
    setSelectedMode(value);
    setInvertCard(value === 'SELL');
    setActionBtnText(`Exchange`);
    setExchangeValue('0.00');
    setIsValidSwap(false);
  };

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  const handleMetamask = useCallback(() => {
    // Wrap the asynchronous logic in a Promise
    return new Promise((resolve, reject) => {
      (async () => {
        // Check if the account is already set (not null)
        if (account !== null) {
          resolve(account); // Resolve the promise with the existing account
          return;
        }

        // Check if MetaMask is available
        if (typeof window.ethereum === 'undefined') {
          const walletexistserror = new Error('MetaMask is not available.');
          swalError({
            message: 'MetaMask is not available.',
            textButton: 'Try Again',
          });
          reject(walletexistserror);
          return;
        }

        // Request access to the MetaMask accounts
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          // Get the selected account address
          const signer = provider.getSigner();
          const selectedAddress = await signer.getAddress();
          setAccount(selectedAddress);
          resolve(selectedAddress); // Resolve the promise with the selected address
        } catch (walletError) {
          setAccount(null);
          const walletconnecterror = new Error(
            'Connection to MetaMask failed.'
          );
          swalError({
            message: 'Connection to MetaMask failed.',
            textButton: 'Try Again',
          });
          reject(walletconnecterror); // Reject the promise with the error
          console.error(walletError);
        }
      })();
    });
  }, [account]);

  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++++++
  // Add a listener for account changes when the component mounts
  useEffect(() => {
    const handleAccountsChanged = (accounts: string[]) => {
      // console.log('Account change detected');
      setExchangeValue('0.00');
      setError('');
      if (accounts.length > 0) {
        const checksumAddress = ethers.utils.getAddress(accounts[0]);
        setAccount(checksumAddress); // Assuming you want to use the first account
      } else {
        setAccount(null);
      }
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      // Remove the listener when the component unmounts
      if (window.ethereum) {
        window.ethereum.removeListener(
          'accountsChanged',
          handleAccountsChanged
        );
      }
    };
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // BALANCE POLLING
  useEffect(() => {
    if (account !== null) {
      // Initial fetch
      fetchBalances();
      fetchLiquidity();

      // POLL BALANCES
      if (pollingEnabled) {
        const interval = setInterval(() => {
          fetchBalances();
        }, 30000);
        // Cleanup function: Clear the interval when the component unloads
        return () => {
          clearInterval(interval);
        };
      }
    }
    return undefined;
  }, [pollingEnabled, account]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Changes input placeholder once user connects wallet
  useEffect(() => {
    if (account !== null) {
      setPlaceHolder('ENTER AMOUNT HERE');
    }
  }, [account]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleCloseCongrats = useCallback(() => {
    // console.log('Close modal');
    setShowCongrat(false);
  }, [showCongrat, congratHash]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleC14PurchaseClick = useCallback(() => {
    handleMetamask()
      .then((selectedAddress) => {
        // const ethId = '5f86f8ad-f546-4aeb-b77e-d75733834b39';
        // const usdtId = '70b9c29c-0b0e-4497-bf7b-a38a47221c84';
        const usdpiId = '5910b23a-0fa6-46f8-ab1c-6e6a71cc0a69';
        const c14url = `https://pay.c14.money/?targetAssetId=${usdpiId}&targetAddress=${selectedAddress}&bgColor=282828&mainColor=17171a&targetAssetIdLock=true`;
        window.open(c14url, '_blank');
      })
      .catch((mmerror) => {
        // something
      });
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleImportUsdt = useCallback(async () => {
    handleMetamask()
      .then((selectedAddress) => {
        networkSwitch('NETWORK')
          .then(async (response) => {
            // console.log('response: %s', response);
            // console.log('import to wallet');

            const tokenName = 'USDPI';
            const tokenAddress = process.env.REACT_APP_BSC_USDPI;
            const tokenSymbol = 'USDPI';
            const tokenDecimals = '18';
            const tokenImage = '';
            const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
            const chainInt = parseInt(chainNo || '0', 16);
            const tprovider = (window as any).ethereum;
            const token = {
              type: 'ERC20',
              options: {
                address: tokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals,
                image: tokenImage,
              },
            };
            tprovider
              .request({
                method: 'wallet_watchAsset',
                params: {
                  type: 'ERC20',
                  options: token.options,
                  chainId: chainInt,
                  token,
                },
              })
              .then((addresponse: any) => {
                // console.log(`Added ${tokenName} (${tokenSymbol}) to MetaMask`);
                // console.log(addresponse);
              })
              .catch((adderror: Error) => {
                console.error(
                  `Error adding ${tokenName} (${tokenSymbol}) to MetaMask`,
                  adderror
                );
              });
          })
          .catch((swerr) => {
            // something
          });
      })
      .catch((mmerror) => {
        // something
      });
  }, []);

  // Function to handle keyboard events
  const handleKeyDown: React.KeyboardEventHandler<HTMLSpanElement> = (e) => {
    if (e.key === 'Enter') {
      // Handle Enter key press
      // handleClick();
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleCloseUpdate = useCallback(() => {
    setShowUpdate(false);
  }, [showUpdate]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleCloseUpdateComplete = useCallback(() => {
    setShowUpdateComplete(false);
  }, [showUpdateComplete]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Called from ModalClearApproval
  // ++++++++++++++++++++++++++++++++++++++
  const handleUpdateBtn = useCallback(async () => {
    // pass
    console.log('UPDATING APPROVAL');
    setShowUpdate(false);
    setProcessText('Reset approval');
    setLoading(true);

    try {
      const tprovider = new ethers.providers.Web3Provider(window.ethereum);
      const tsigner = tprovider.getSigner();
      const contractusdt = new ethers.Contract(
        process.env.REACT_APP_BSC_USDT as string,
        erc20Abi,
        tprovider
      );
      const overrides = { gasLimit: 50000 };

      setProcessText('Clearing approval, please confirm transaction.');

      const trxapprove = await contractusdt
        .connect(tsigner)
        .approve(process.env.REACT_APP_ETH_SWAP, '0', {
          from: await tsigner.getAddress(),
          ...overrides,
        });

      // console.log(trxapprove);
      setProcessText('Waiting for approval transaction to complete');

      const receipt = await trxapprove.wait(3);

      setLoading(false);
      setShowUpdateComplete(true);
      // setShowUpdateComplete
    } catch (resetError) {
      setLoading(false);
      swalError({
        message: 'Approval reset failed.',
        textButton: 'Try Again',
      });
    }
  }, []);

  return (
    <>
      <Container className="p-5">
        <div className="container-fluid py-0 border-0">
          <div className="row align-items-center">
            <div className="col-3 col-xl-1 text-end">
              <img src={logoSwap} alt="Logo USDPI" className="logo-usdpi" />
            </div>
            <div className="col-7 col-xl-5">
              <h2 className="fw-bold mb-0">USDPI</h2>
            </div>
            <div className="col-lg-6 text-end">
              {account === null ? (
                <ConnectBtn
                  type="button"
                  className="btn-wallet me-4 me-lg-0"
                  onClick={handleMetamask}
                >
                  <span>Connect Wallet</span>
                </ConnectBtn>
              ) : (
                <WalletAvatarPageswap selectedAddress={account || ''} />
              )}
            </div>
            {/* <div className="col-12">
              <h1 className="title">Swap</h1>
            </div> */}
          </div>
        </div>

        <div className="container-fluid mt-5">
          <div className="row  mt-4">
            <div className="col-lg-9">
              <div className="row px-4 px-sm-5 justify-content-center">
                <div className="col-12 d-flex bd-bottom align-items-end px-0">
                  <div className="w-100">
                    <button type="button" className="btn-swap active px-4 py-3">
                      SWAP
                    </button>
                    <button
                      type="button"
                      className="btn-swap btn-secondary px-4 py-3"
                      disabled
                    >
                      ORDER HISTORY
                    </button>
                    <span className="buy-text py-3 pl-4 pr-5">
                      Buy USDPI with Debit Card
                    </span>{' '}
                    <a
                      href="https://pay.c14.money"
                      target="_blank"
                      className="btn buy-here ms-2 py-2 px-3"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        handleC14PurchaseClick();
                      }}
                    >
                      BUY INSTANTLY
                    </a>
                    {/* <a
                      href="https://pay.c14.money"
                      target="_blank"
                      className="btn import-usdpi ms-2 py-2 px-3"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        handleImportUsdt();
                      }}
                    >
                      IMPORT USDPI TO WALLET
                    </a> */}
                  </div>
                  <div className="w-25 text-end d-flex justify-content-end align-items-center">
                    <div className="d-flex flex-column align-items-end">
                      <div className="d-flex align-items-center  no-wrap">
                        <RefreshBtn
                          type="button"
                          onClick={fetchAllBalances}
                          style={{ cursor: 'pointer' }}
                          className="refresh-button"
                        >
                          <img
                            src={refreshBalances}
                            alt="Refresh Balances"
                            className="mr-2"
                          />
                          Your USDPI Balance
                        </RefreshBtn>
                      </div>
                      <div className="d-flex justify-content-end">
                        <span
                          className="value"
                          title="Click to import USDPI to your wallet."
                          onClick={handleImportUsdt}
                          role="button"
                          tabIndex={0}
                          onKeyDown={handleKeyDown}
                        >
                          ${usdpi}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row px-5 mt-3">
                <div className="col-8 d-flex flex-column px-0">
                  <div className="d-flex justify-content-between">
                    <div className="w-45 order-0 p-3 radio-buy-sell">
                      <input
                        type="radio"
                        id="buyRadio"
                        value="BUY"
                        checked={selectedMode === 'BUY'}
                        onChange={handleModeChange}
                      />
                      <label htmlFor="buyRadio">&nbsp;Buy USDPI</label>
                    </div>
                    <div className="w-45 order-1 p-3 radio-buy-sell">
                      <input
                        type="radio"
                        id="sellRadio"
                        value="SELL"
                        checked={selectedMode === 'SELL'}
                        onChange={handleModeChange}
                      />
                      <label htmlFor="sellRadio">&nbsp;Sell USDPI</label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center cards-shadow">
                    <div
                      className={`w-45 ${!invertCard ? 'order-2' : 'order-4'}`}
                    >
                      <Card
                        background={tokenSelected.cardStyle}
                        className="yellow-card p-4"
                      >
                        <div className="text-end">
                          <img src={tokenSelected.logo} alt="Logo BUSD" />
                        </div>
                        <h2>{tokenSelected.name}</h2>
                        <div className="d-flex mt-4">
                          <div className="w-50 h6 text-white">
                            {/* $1 */}
                            {tokenSelected.name === 'ETH'
                              ? `$ ${eth_value_f}`
                              : `$1`}
                          </div>
                          <div className="w-50 h6 text-white">
                            {tokenSelected.name === 'ETH'
                              ? `${ethBal_f} ${tokenSelected.name}`
                              : `${usdt} ${tokenSelected.name}`}
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="w-50 h6 text-white">
                            {tokenSelected.name === 'ETH'
                              ? '\u00A0'
                              : 'Spending Limit'}
                          </div>
                          <div className="w-50 h6 text-white">
                            {tokenSelected.name === 'ETH'
                              ? `\u00A0`
                              : `${usdt_allowance_f} ${tokenSelected.name}`}
                          </div>
                        </div>
                      </Card>
                    </div>
                    <div className="w-10 text-center order-3">
                      <button
                        type="button"
                        className="rounded-circle border-0 arrows"
                        style={{ width: '46px', height: '46px' }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ width: '100%', height: '100%' }}
                        >
                          <img
                            src={swapRight}
                            alt="Arrows"
                            style={{
                              width: '46px',
                              height: '46px',
                            }}
                          />
                        </div>
                      </button>
                    </div>
                    <div
                      className={`w-45 ${!invertCard ? 'order-4' : 'order-2'}`}
                    >
                      <Card
                        background="linear-gradient(84.9deg, #2fbf7f 10.36%, #3cc9ad 97.55%);"
                        className="green-card p-4"
                      >
                        <div className="text-end">
                          <img src={logoUsdpi} alt="Logo USDPI" />
                        </div>
                        <h2>USDPI</h2>
                        <div className="d-flex mt-4">
                          <div className="w-50 h6 text-white">$1</div>
                          <div className="w-50 h6 text-white">
                            {usdpi} USDPI
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="w-50 h6 text-white">&nbsp;</div>
                          <div className="w-50 h6 text-white">&nbsp;</div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className="col-4 px-0 ps-5">
                  <div className="d-flex justify-content-between mt-3">
                    <div>
                      <span
                        className="wm-tooltip text-table"
                        data-tooltip="Transaction fees can fluctuate based on network conditions such as demand and congestion. Higher demand can lead to higher fees, while lower demand can result in lower fees. The fees are not fixed, but rather vary based on network conditions at any given time."
                      >
                        Network Fees{' '}
                        <HiOutlineInformationCircle
                          className="ms-3"
                          size={25}
                          color="#4F89FF"
                        />
                        <br />
                        <span className="estimate">
                          Estimate only, fees will vary
                        </span>
                      </span>
                    </div>
                    <h3 className="values text-end">
                      ${feeValue}
                      <br />
                      <span>
                        {feeValue} {invertCard ? 'USDPI' : tokenSelected.name}
                      </span>
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between text-end mt-2">
                    <span className="text-table">Send</span>
                    <h3 className="values color-negative">
                      -${exchangeValue}
                      <br />
                      <span>
                        -
                        {tokenSelected.name === 'ETH'
                          ? exchangeValueEth
                          : exchangeValue}{' '}
                        {/* -{exchangeValue}{' '} */}
                        {invertCard ? 'USDPI' : tokenSelected.name}
                      </span>
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between text-end mt-2">
                    <span className="text-table">
                      Receive <i className="estimate">(estimated)</i>
                    </span>
                    <div>
                      <h3 className="values color-positive mb-0">
                        ${receiveValue}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <Form
                ref={formRef}
                onSubmit={handleSubmitExchange}
                className="row px-5 mt-5 pt-3"
              >
                <div className="col-12 px-0">
                  <div className="text-center bg-number py-3 position-relative">
                    {showMaxBtn() ? (
                      <button
                        onClick={handleMax}
                        type="button"
                        className="position-absolute"
                      >
                        Max
                      </button>
                    ) : null}
                    <InputMask
                      id="priceInput"
                      kind="money"
                      name="price"
                      className="input-mask border-0 bg-transparent px-4"
                      options={{
                        unit: '$',
                        delimiter: ',',
                        separator: '.',
                      }}
                      placeholder={placeHolder}
                      onChange={handleChangePrice}
                      value={exchangeValue !== '0,00' ? exchangeValue : ''}
                      // value={exchangeValue}
                    />
                  </div>

                  {error && (
                    <span className="small text-danger error text-center w-100 d-block mt-1">
                      {error}
                    </span>
                  )}
                </div>
                <div className="col-12 px-0 mt-4 position-relative">
                  <button
                    type="submit"
                    className="btn btn-exchange fw-bold text-black"
                    disabled={!isValidSwap}
                  >
                    {actionBtnText}
                  </button>
                </div>
              </Form>
            </div>
            <div className="col-lg-3">
              {selectedMode === 'BUY' && (
                <div className="box token-information">
                  <h3>Choose Token & Network</h3>
                  <div className="token-information">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center coin">
                        <div className="space" />
                        <small>Token</small>
                      </div>
                      <div className="d-flex align-items-center coin">
                        <div className="space" />
                        <small>Network</small>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="position-relative">
                        <button
                          type="button"
                          className="border-0 bg-transparent d-flex align-items-center coin"
                          onClick={() => handleClick('token')}
                        >
                          <img
                            src={tokenSelected.logo}
                            alt={tokenSelected.name}
                          />
                          <p className="mb-0 coin-name">{tokenSelected.name}</p>
                          <img src={arrow} alt="Arrow" className="arrow-down" />
                        </button>
                        <SelectCoin
                          left="0"
                          active={type === 'token'}
                          className="position-absolute"
                        >
                          {tokens.map((token, index) => (
                            <Fragment key={token.name}>
                              {index !== 0 && <hr />}
                              <button
                                type="button"
                                className="border-0 bg-transparent d-flex align-items-center coin"
                                onClick={() => handleSelectToken(token)}
                              >
                                <img src={token.logo} alt={token.name} />
                                <p className="mb-0 coin-name">{token.name}</p>
                              </button>
                            </Fragment>
                          ))}
                        </SelectCoin>
                      </div>
                      {/* <img src={arrow} alt="Arrow" /> */}
                      <div className="position-relative">
                        <button
                          type="button"
                          className="border-0 bg-transparent d-flex align-items-center coin"
                          // onClick={() => handleClick('network')}
                        >
                          <img
                            src={networkSelected.logo}
                            alt={networkSelected.name}
                          />
                          <p className="mb-0 coin-name">
                            {networkSelected.name}
                          </p>
                          {/* <img src={arrow} alt="Arrow" className="arrow-down" /> */}
                        </button>
                        <SelectCoin
                          right="0"
                          active={type === 'network'}
                          className="position-absolute"
                        >
                          {networks.map((network, index) => (
                            <Fragment key={network.name}>
                              {index !== 0 && <hr />}
                              <button
                                type="button"
                                className="border-0 bg-transparent d-flex align-items-center coin"
                                onClick={() => handleSelectNetwork(network)}
                              >
                                <img src={network.logo} alt={network.name} />
                                <p className="mb-0 coin-name">{network.name}</p>
                              </button>
                            </Fragment>
                          ))}
                        </SelectCoin>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedMode === 'SELL' && (
                <div className="box">
                  <h3>Available Liquidity</h3>
                  <div className="tokens-information overflow-auto">
                    {tokens.map((token) => {
                      if (token.name === 'ETH') {
                        return null;
                      }
                      return (
                        <div key={token.name} className="subbox">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center coin">
                              <img src={token.logo} alt={token.name} />
                              <p className="mb-0 coin-name">{token.name}</p>
                            </div>
                            {/* <img src={arrow} alt="Arrow" /> */}
                            <div className="d-flex align-items-center coin">
                              <img
                                src={networkSelected.logo}
                                alt={networkSelected.name}
                              />
                              <p className="mb-0 coin-name">
                                {networkSelected.name}
                              </p>
                            </div>
                          </div>
                          <small>
                            {networkSelected.name === 'ETH'
                              ? token.descriptionEth
                              : token.descriptionBnb}
                          </small>
                          <p className="price">
                            {/* {networkSelected.name === 'BNB'
                              ? token.priceEth
                              : token.priceBnb} */}
                            {token.priceEth}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
      <Loading
        type="dark"
        // srcImg={logoWhite}
        srcImg={logoSwap}
        text="SWAPPING TOKENS"
        className="zoom-1-3"
        active={loading}
      />
      <ModalExchangeSuccessful
        show={showCongrat}
        handleClose={handleCloseCongrats}
        hash={congratHash}
      />
      <ModalClearApproval
        showUpdate={showUpdate}
        showUpdateComplete={showUpdateComplete}
        handleCloseUpdate={handleCloseUpdate}
        handleCloseUpdateComplete={handleCloseUpdateComplete}
        handleUpdateBtn={handleUpdateBtn}
        handleSwapBtn={handleSubmitExchange}
      />
    </>
  );
};

export default PageSwap;
